
@tailwind base;
@tailwind components;
@tailwind utilities;

*{font-family: montserrat;}

@font-face {font-family:'montserrat'; src:url('./font/Montserrat-Thin.ttf'); font-weight:1;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-ThinItalic.ttf'); font-weight:1; font-style:italic;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-ExtraLight.ttf'); font-weight:200;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-ExtraLightItalic.ttf'); font-weight:200; font-style:italic;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-Light.ttf'); font-weight:300;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-LightItalic.ttf'); font-weight:300; font-style:italic;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-Regular.ttf'); font-weight:400;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-Medium.ttf'); font-weight:500;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-MediumItalic.ttf'); font-weight:500; font-style:italic;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-SemiBold.ttf'); font-weight:600;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-SemiBoldItalic.ttf'); font-weight:600; font-style:italic;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-Bold.ttf'); font-weight:700;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-BoldItalic.ttf'); font-weight:700;font-style:italic;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-ExtraBold.ttf'); font-weight:800;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-ExtraBoldItalic.ttf'); font-weight:800;font-style:italic;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-Black.ttf'); font-weight:900;}
@font-face {font-family:'montserrat'; src:url('./font/Montserrat-BlackItalic.ttf'); font-weight:900;font-style:italic;}


.loadership_HDAFU {
	display: flex;
	position: relative;
	width: 68px;
	height: 68px;
  }

  .loadership_HDAFU div {
	animation: loadership_HDAFU_roller 1.2s infinite;
	animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
	transform-origin: 34px 34px;
  }
  .loadership_HDAFU div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #ffffff;
  }
  
  .loadership_HDAFU div:nth-child(1) {
		animation-delay: 0.00s;
	  }
	  .loadership_HDAFU div:nth-child(1):after {
		top: 60px;
		left: 30px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(2) {
		animation-delay: -0.04s;
	  }
	  .loadership_HDAFU div:nth-child(2):after {
		top: 56px;
		left: 45px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(3) {
		animation-delay: -0.07s;
	  }
	  .loadership_HDAFU div:nth-child(3):after {
		top: 45px;
		left: 56px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(4) {
		animation-delay: -0.11s;
	  }
	  .loadership_HDAFU div:nth-child(4):after {
		top: 30px;
		left: 60px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(5) {
		animation-delay: -0.14s;
	  }
	  .loadership_HDAFU div:nth-child(5):after {
		top: 15px;
		left: 56px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(6) {
		animation-delay: -0.18s;
	  }
	  .loadership_HDAFU div:nth-child(6):after {
		top: 4px;
		left: 45px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(7) {
		animation-delay: -0.22s;
	  }
	  .loadership_HDAFU div:nth-child(7):after {
		top: 0px;
		left: 30px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(8) {
		animation-delay: -0.25s;
	  }
	  .loadership_HDAFU div:nth-child(8):after {
		top: 4px;
		left: 15px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(9) {
		animation-delay: -0.29s;
	  }
	  .loadership_HDAFU div:nth-child(9):after {
		top: 15px;
		left: 4px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(10) {
		animation-delay: -0.32s;
	  }
	  .loadership_HDAFU div:nth-child(10):after {
		top: 30px;
		left: 0px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(11) {
		animation-delay: -0.36s;
	  }
	  .loadership_HDAFU div:nth-child(11):after {
		top: 45px;
		left: 4px;
	  }
	  
	  
.loadership_HDAFU div:nth-child(12) {
		animation-delay: -0.40s;
	  }
	  .loadership_HDAFU div:nth-child(12):after {
		top: 56px;
		left: 15px;
	  }
	  
	  

  @keyframes loadership_HDAFU_roller {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }



/*
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

*{box-sizing:border-box;font-size: 12px;font-family: montserrat;}
body{color: #273237;}
input:-webkit-autofill,textarea:-webkit-autofill{-webkit-box-shadow:0 0 0 50px #CEF4FB inset;-webkit-text-fill-color: #003459;}
input:-webkit-autofill:focus,textarea:-webkit-autofill:focus{-webkit-box-shadow:0 0 0 50px #CEF4FB inset;-webkit-text-fill-color: #003459;}
button:focus,input:focus,textarea:focus{border-color:#09CAEC;box-shadow: 0px 0px 3px #0062FF;outline:0;}
input::placeholder,textarea::placeholder{color:rgba(126, 133, 142, 0.6);}
input::-ms-input-placeholder,textarea::-ms-input-placeholder{color:rgba(126, 133, 142, 0.6);}

h2{margin: 0 0 20px 0;text-align: center;font-weight: 700;font-size: 2rem;}
h3{margin: 0 0 10px 0;text-align: center;font-weight: 600;font-size: 1.5rem;}
p{}
p b{font-size: inherit;}

@media only screen and (min-width: 768px) {
	h2{margin: 0 0 40px 0;text-align: center;font-weight: 700;font-size: 2.5rem;}
	h3{margin: 0 0 20px 0;text-align: center;font-weight: 600;font-size: 1.75rem;}
}

.align-items-center{align-items: center !important;}
.align-items-start{align-items: flex-start !important;}

.bg-blue{background-color: #0883B9;}
.bg-dark{background-color: #223143;}

.border-bottom-dashed{border-bottom: 1px dashed #D3DCE2;}

.branch-bough{margin: 20px 0 0 0;}
.branch-bough-shape{font-size: 1.1rem;background-color: #EAEFF2;
	border-radius: 0 20px 20px 0;display:inline-block;}
.branch-bough-label{padding: 10px 20px 10px 16px;font-size: 1.25rem;font-weight: 600;text-transform: uppercase;}
.branch-bough-label-input{border-radius: 0 20px 20px 0;padding: 9px 20px 9px 16px;
	font-size: 1.25rem;font-weight: 600;text-transform: uppercase;}
.branch-bough-top{display: flex;flex-direction: row;align-items: center;margin-bottom: 16px;}
.branch-bough-drop{margin-left: auto;}

.branch-sprig{display: flex;flex-direction: column;align-items: stretch;
	padding: 0 0 11px 0;}

.branch-sprig-body{font-weight: 400;font-size: 1.4rem;padding: 11px 17px;line-height: 1.5;margin: 0 0 0 0;}
.branch-sprig-sm .branch-sprig-body{font-size:1.125rem;}
.branch-sprig-body-editing{flex-grow: 1;margin-bottom:10px;}
.branch-sprig-body-editing .input{font-weight: 400;font-size: 1.4rem;}
.branch-sprig-dot{width: 22px;height: 22px;min-width: 22px;background-color: #223143;color: #FFF;
	font-size: 1.2rem;font-weight: 800;text-align: center;
	border-radius: 11px;margin: 12px 0 2px 0;min-width: 18px;transition:background-color .15s ease-in-out;
	display: flex;align-items: center;justify-content: center;}
.branch-sprig-dot-add{width:22px;height:22px;background-color:rgba(34,49,67,0.5);color:#FFF;
	border-radius: 11px;cursor:pointer;outline: none;border: none;margin: 2px 0 2px 0;opacity: 0.2;}
.branch-sprig-dot-add:hover{background-color:rgba(34,49,67,1);opacity: 1;}
.branch-sprig-dot-icon {width: 10px;height: 10px;}
.branch-sprig-editing{flex-grow: 1;margin-bottom:10px;}
.branch-sprig-info{border-bottom: 1px dashed #D3DCE2;background-color: #E6F5FB;}
.branch-sprig-interactive{cursor: pointer;opacity:0.9;transition:opacity .15s ease-in-out;}
.branch-sprig-interactive:hover{opacity: 1;}
.branch-sprig-interactive:hover .branch-sprig-dot{}
.branch-sprig-interactive:hover .branch-sprig-label{}
.branch-sprig-interactive:hover .branch-sprig-body{}
.branch-sprig-label{font-weight: 700;padding: 11px 16px;font-size: 1.4rem;line-height: 1.5;}
.branch-sprig-sm .branch-sprig-label{font-size: 1.125rem;}
.branch-sprig-label-input{font-weight: 700;}
.branch-sprig-left{display: flex;flex-direction: row;align-items: flex-start;margin:0 10px 0 10px;min-height: 50px;}
.branch-sprig-left-editing{flex-grow: 1;}
.branch-sprig-num{line-height: 22px;}
.branch-sprig-right{margin: 0 10px 0 10px;display: flex;flex-direction: column;justify-content: stretch;align-items: stretch;}
.branch-sprig-right-row{display: flex;flex-direction: column;justify-content: stretch;align-items: stretch;}
.branch-sprig-title{font-weight: 700;font-size: 1.2rem;padding: 4px 8px;line-height: 1.5;}

@media only screen and (min-width: 768px){
	.branch-sprig{flex-direction: row;align-items: flex-start;padding: 0 0 11px 0;min-height: 50px;}
	.branch-sprig-body{padding: 11px 17px;}
	.branch-sprig-body-editing .input{font-weight: 400;font-size: 1.4rem;}
	.branch-sprig-left{min-width: 33%;max-width: 33%;min-height: 50px;}
	.branch-sprig-dot{margin: 12px 6px 0 6px;width: 22px;height: 22px;min-width: 22px;}
	.branch-sprig-dot-add{margin: 12px 6px 0 6px;}
	.branch-sprig-dot-icon {width: 10px;height: 10px;}
	.branch-sprig-right{margin: 0;flex-grow: 1;}
	.branch-sprig-right-row{display: flex;flex-direction: row;justify-content: stretch;align-items: flex-end;}
	.branch-sprig-title{font-size: 1.4rem;padding: 13px 16px;}
}

.btn{background:#0883B9;border-radius:80px;display:inline-block;font-weight:600;color:#FFF;text-align:center;vertical-align:middle;
	-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;
	padding:12px 24px;font-size:1.35rem;line-height:1.5;cursor:pointer;
	transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	text-decoration:none;}
.btn:hover{background:#0875A5;}
.btn:active{background:#0875A5;}

.btn-block{display:block;width:100%;}
.btn-close{cursor:pointer;opacity:0.8;}
.btn-close:hover{opacity:1;}
.btn-light{background-color: #ECF1F5;color: #223143;}
.btn-light:hover{background:#D2D9DF;color: #223143;}
.btn-light:active{background:#D2D9DF;color: #223143;}
.btn-light-danger{background-color: #ECF1F5;color: #223143;}
.btn-light-danger:hover{background:#BE2424;color: #FFF;}
.btn-light-danger:active{background:#BE2424;color: #FFF;}
.btn-danger{background-color: #BE2424;color: #FFF;}
.btn-danger:hover{background:#9F2033;color: #FFF;}
.btn-danger:active{background:#9F2033;color: #FFF;}

.btn-sm{padding:8px 16px;font-size:1.15rem;}

.btn-round{width: 50px;height: 50px;border-radius: 100px;background-color: #FFF;color: #223143;
	transition:color .3s ease-out,border-color .3s ease-out, transform .3s ease-out;
	padding: 0;border: 2px solid #D2D9DF;display: flex;flex-direction: row;align-items: center;justify-content: center;
}
.btn-round:hover{background:#FFF;color: #223143;border-color: #223143;transform: scale(1.1);box-shadow: 2px 3px 4px 2px rgba(34,49,67,0.05);}
.btn-round-icon{width: 20px;height: 20px;}
.btn-round-sm{width: 22px;height: 22px;}
.btn-round-sm .btn-round-icon{width: 14px;height: 14px;margin: 2px;}
.btn-round-primary{background-color: #0883B9;color: #FFF;border-color: #0883B9;}
.btn-round-primary:hover{background-color: #095598;color: #FFF;border-color: transparent;}
.btn-round-success{background-color: #9AC255;color: #FFF;border-color: #9AC255;}
.btn-round-success:hover{background-color: #7DA950;color: #FFF;border-color: transparent;}

.btn-square{width: 50px;height: 50px;border-radius: 3px;background-color: #FFF;color: #223143;
	transition:color .3s ease-out,border-color .3s ease-out, transform .3s ease-out;
	text-align: center;padding: 0;border: 2px solid #D2D9DF;
}
.btn-square:hover{background:#FFF;color: #223143;border-color: #223143;transform: scale(1.1);box-shadow: 2px 3px 4px 2px rgba(34,49,67,0.05);}
.btn-square-icon{width: 20px;height: 20px;margin: 14px;}

.chapter-frame{padding-top:60px;}
.chapter-body{font-size: 1.25rem;font-weight: 400;line-height: 1.35;margin: 0 0 60px 0;white-space: pre-wrap;}
.chapter-row{max-width: 1100px;margin: 0 auto;padding: 40px 20px 40px 40px;}
.chapter-title{font-size: 3rem;text-align: center;margin: 0 0 4px 0;font-weight: 800;}
.chapter-num{font-size: 1.5rem;text-align: center;font-weight: 700;}
.chapter-section-mb{margin-bottom: 40px;}

@media only screen and (min-width: 768px) {
	.chapter-body{font-size: 1.5rem;font-weight: 400;line-height: 1.35;margin: 0 0 60px 0;white-space: pre-wrap;}
	.chapter-row{max-width: 1100px;margin: 0 auto;padding: 80px 40px 80px 80px;}
	.chapter-num{font-size: 2.5rem;text-align: center;font-weight: 700;}
	.chapter-section-mb{margin-bottom: 70px;}
	.chapter-title{font-size: 4.2rem;text-align: center;margin: 0 0 10px 0;font-weight: 800;}
}

.chapter-add{position: relative;}
.chapter-add-body{min-height: 250px;}
.chapter-add-actions{text-align: right;margin-bottom:10px;}

.chapter-members{display: flex;flex-direction: row;align-items: center;min-height: 70px;flex-wrap: wrap;}
.chapter-member{cursor: pointer;transition:transform .3s ease-out, box-shadow .3s ease-out;box-shadow: 1px 1px 3px 3px transparent;border-radius: 40px;
	position: relative;float: left;box-shadow: 1px 1px 3px 3px transparent;width: 50px;height: 50px;border-radius: 50px;
	background-size: cover;background-position: center center;flex-wrap: wrap;margin: 0 12px 18px 0;}
.chapter-member-tooltip{position: absolute;top: 75px;left: -30px;right: -30px;color: #223143;font-size: 1rem;display: none;
	text-align: center;font-weight: 500;}
.chapter-member.no-hover{cursor: default;}
.chapter-member:hover{transform: scale(1.1);z-index: 2;border: 2px solid #FFF;box-shadow: 1px 3px 4px 1px rgba(34,49,67,0.06);}
.chapter-member:hover .chapter-member-tooltip{display: block;}
.chapter-members-add{margin-bottom: 17px;}

@media only screen and (min-width: 768px) {
	.chapter-member{width: 70px;height: 70px;margin: 0 12px 40px 0;}
	.chapter-members-add{margin-bottom: 39px;}
}

.chapter-when{display: flex;flex-direction: row;align-items: center;}
.chapter-when .input{margin-right: 10px;}
.chapter-when .input:last-child{margin-right: 0;}
.chapter-when-separator{margin-right: 10px;font-weight: 500;font-size: 1.25rem;}

.cite-tree{display: inline-block;font-size: 1.4rem;font-weight: 700;}
.cite-tree .btn-round{display: inline-block;margin-right: 4px;}

.community-hero{display: flex;flex-direction: row;align-items: center;justify-content: center;overflow: hidden;
	position: relative;background-color: #223143;
	background-image: url('image/img-community.png');background-repeat: no-repeat;background-size: cover;background-position: center 0px;}
.community-hero-logo{max-width: 120px;width: 30%;padding: 30px 0;position: relative;z-index: 10;}
.community-hero-backdrop{position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 1;}

.community-welcome{max-width: 1100px;margin: 0 auto;padding: 60px 20px;}
.community-welcome .community-welcome-intro{font-size: 1.5rem;line-height: 1.4;margin: 0 auto 60px auto;max-width: 800px;}

.connect-hero{display: flex;flex-direction: row;align-items: center;justify-content: center;overflow: hidden;
	position: relative;background-color: #223143;
	background-image: url('image/img-connect.png');background-repeat: no-repeat;background-size: cover;background-position: center 0px;}
.connect-hero-logo{max-width: 120px;width: 30%;padding: 30px 0;position: relative;z-index: 10;}
.connect-hero-backdrop{position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 1;}

.connect .highlight-block{margin-top: 60px;}

.connect-welcome{max-width: 1100px;margin: 0 auto;padding: 60px 20px;}
.connect-welcome .connect-welcome-intro{font-size: 1.5rem;line-height: 1.4;margin: 0 auto 60px auto;max-width: 800px;}


.container{max-width: 1100px; margin: 0 auto;position: relative;z-index: 10;}

.content-frame{margin:0 auto;padding:70px 20px;max-width:1000px;}
.content-frame-content{}

.content-frame-bottom{height:23px;border-radius:0 0 17px 17px;background-color: #EAEFF2;}

.content-frame-title{padding: 10px 0;}
.content-frame-title .input{font-size:2.8rem;font-weight: 900;}
.content-frame-title-border{border: 1px dashed #EAEEF2;}
.content-frame-top{height:33px;border-radius:17px 17px 0 0;background-color: #EAEFF2;
	display: flex;flex-direction: row;align-items: center;}
.content-frame-top-actions{margin-left: auto;}
.content-frame-top-action{color: #223143;font-weight: 500;cursor: pointer;font-size: 1.25rem;margin-right: 20px;}
.content-frame-top-action:hover{color: #03A9C2;}
.content-frame-top-drop{margin: 4px 16px 0 auto;}
.content-frame-top-drop-icon{width: 22px;height: 22px;}
.content-frame-top-type{background-color:#223143;display:inline-block;height:33px;line-height:33px;
	border-radius:17px 0 0 0;}
.content-frame-top-type-icon{width:14px;color:#FFF;margin:0 12px 0 16px;vertical-align: top;position:relative;top:9px;}
.content-frame-top-type-label{color:#FFF;display:inline-block;margin:0 16px 0 0;font-size:1.25rem;font-weight:600;}
.content-frame-top-subtype{background-color:#0883B9;display:inline-block;height:33px;line-height:33px;}
.content-frame-top-subtype-label{color:#FFF;display:inline-block;margin:0 16px 0 16px;font-size:1.5rem;font-weight:600;}
@media only screen and (min-width: 768px) {
	.content-frame{padding:70px;}
}

.content-unit{display: flex;flex-direction: row;padding: 8px 0 0 0;position:relative;}
.content-unit-actions{margin-top: 8px;}
.content-unit-block{margin-bottom: 40px;}
.content-unit-heading{font-size:1.4rem;padding: 13px 16px;}
.content-unit-left{width: 220px;}
.content-unit-right{flex-grow: 1;}
.content-unit-heading{font-weight:700;}

.content-unit-tag{font-size:1.25rem;font-weight: 600;background-color: #EAEFF2;padding: 8px 20px 8px 20px;
	border-radius: 0 20px 20px 0;}
.content-unit-tag-row{margin: 20px 0 0 0;display: flex;flex-direction: row;align-items: center;}

.content-unit-bullet{width:22px;height:22px;background-color:#223143;color:#FFF;
	font-size:1.2rem;font-weight:800;line-height:22px;text-align: center;border-radius: 11px;
	margin: 12px 6px 10px 20px;}
.content-unit-bullet-icon{width:12px;position:relative;}
.content-unit-add-btn{width:22px;height:22px;background-color:rgba(34,49,67,0.5);color:#FFF;
	border-radius: 11px;margin: 12px 6px 10px 20px;cursor:pointer;outline: none;border: none;}
.content-unit-add-btn:hover{background-color:rgba(34,49,67,1);}
.content-unit-add-btn-icon{width:12px;position:relative;top:1px;left:-1px;}

.content-unit-close{position:absolute;right:-30px;width:30px;top:0;bottom:0;
	display:flex;flex-direction: row;align-items: center;opacity:0;cursor:pointer;justify-content: center;}
.content-unit:hover .content-unit-close{opacity:1;}
.content-unit-close-icon{width:18px;color:rgba(34,49,67,0.5);}
.content-unit-close:hover .content-unit-close-icon{color:rgba(34,49,67,1)}
.content-unit-extras{display: flex;flex-direction: row;align-items: center;justify-content: flex-end;margin:0 16px 0 46px;}
.content-unit-extra-heading{font-size: 1.2rem;font-weight: 600;line-height: 1.5;
	display: flex;align-items: center;flex-direction: row;text-transform: uppercase;
	background-color: #F6F8F9; border-radius: 23px 23px 0 0;padding: 10px 16px 8px 16px;}
.content-unit-extra-heading-close{border: none;margin-left: auto;background: transparent;cursor: pointer;}
.content-unit-extra-heading-close .icon{width: 14px;height: 14px;}
.content-unit-extra-expand{flex-grow:1;}
.content-unit-extra-contract{max-width:40px;}
.content-unit-extra-media{padding: 16px;}
.content-unit-extra-prompt{font-size: 1.2rem;font-weight: 400;line-height: 1.5;padding:16px;}
.content-unit-extra-option{color:#223143;}
.content-unit-extra-setting{padding: 16px;}
.content-unit-extra-tab{padding:0 9px;margin:5px;display: flex;flex-direction: row;align-items: center;
	line-height: 30px;position: relative;cursor: pointer;height:30px;background: transparent;border: none;}
.content-unit-extra-tab:focus{outline:none;box-shadow:none;}
.content-unit-extra-tab-icon{width:14px;height:14px;color: rgba(34,49,67,0.4);margin-right:8px;}
.content-unit-extra-skinny{width:220px;}
.content-unit-extra-stat{margin-left:7px;font-weight:700;font-size:1rem;color: rgba(34,49,67,0.2);}
.content-unit-extra-tab:hover .content-unit-extra-tab-icon{color: rgba(34,49,67,1);}
.content-unit-extra-tab:hover .content-unit-extra-tab-stat{color:rgba(34,49,67,1);}
.content-unit-extra-tab.active .content-unit-extra-tab-icon{color: rgba(34,49,67,1);}
.content-unit-extra-tab.active .content-unit-extra-tab-stat{color:rgba(34,49,67,1);}
.content-unit-extra-tab.active:after{content: '';border-radius: 4px;position:absolute;left:0;right:0;bottom:-12px;
	height: 8px;border-top: 4px solid rgba(34,49,67,1);}

.content-section-tag{}

.cu-extras-full{display:flex;flex-driection: row;gap: 20px;margin:0 0 0 268px;}

.d-none{display: none !important;}

.drop-menu{position: relative;z-index:100;cursor: pointer;}
.drop-menu-btn{padding: 6px;}
.drop-menu:hover .drop-menu-icon{color: #223143;}
.drop-menu-icon{width: 16px;height: 16px;color: #D3DAE2;}
.drop-menu-actions{position: absolute;right: 0;display: none;min-width: 120px;
	flex-direction: column;align-items: stretch;background: #FFF;border-radius: 4px;border: 1px solid #EAEFF2;
	box-shadow: 4px 6px 5px rgba(234,239,242,0.5)}
.drop-menu-action{display: flex;flex-direction: row;align-items: center;min-height: 40px;padding:0 16px;
	font-size: 1.15rem;font-weight: 500;cursor: pointer;}
.drop-menu-action:hover{background-color: rgba(8,131,185,0.02);}
.drop-menu-action-icon{width: 14px;height: 14px;margin-right: 10px;}
.drop-menu:hover .drop-menu-actions{display: flex;}

.error-bar{position:fixed;bottom:16px;left:16px;right:16px;background-color:#BE2424;
	display:flex;flex-direction:row;align-items:center;padding:16px 24px;color:#FFF;font-weight:600;
	box-shadow: inset -1px 0px 0px rgba(215,223,233,0.3);z-index: 2000;border-radius: 40px;
	font-size: 1.25rem;}
.error-bar .btn-close{margin-left:auto;}
.error-bar .btn-close .icon{width: 22px;height: 22px;position: relative;top: 1px;}
.error-bar .icon-alert{width:24px;margin-right:12px;}

.font-16{font-size: 1.4rem;}
.font-weight-600{font-weight: 600;}
.font-weight-700{font-weight: 700;}

.form-section{margin-bottom: 70px;}

.float-clear{clear: both;}

.form-group{margin-bottom:0;}
.form-actions{margin-top: 42px;}

.h-screen-125{height: 125vh;}

.highlight-block{padding: 30px 20px;margin: 0 auto;background-color: #EEF4F5;border-radius: 77px;}
.highlight-block h3{margin: 0 0 20px 0;text-align: center;font-weight: 600;font-size: 2rem;}
.highlight-block p{font-size: 1.4rem;line-height: 1.4;margin: 0 0 20px 0;}
.highlight-block-action{text-align: center;}
@media only screen and (min-width: 768px) {
	.highlight-block{padding: 40px 50px;}
}


.home .container{}
.home-chapter{display: block;width: 90px;border-radius: 25px;background-color: #F2F0ED;text-align: center;padding: 11px 0 18px 0;
	margin: 40px auto 40px auto;}
.home-chapter h2{color: #C4C0BB;font-size: 3rem;font-weight: 500;font-family:"Times New Roman", Times, serif;margin: 0;}
.home-chapter h3{color: #C4C0BB;font-size: 1rem; margin: 0;}
.home-hero{display: flex;flex-direction: row;align-items: center;justify-content: center;overflow: hidden;
	position: relative;background-color: #223143;
	background-image: url('image/img-spring.png');background-repeat: no-repeat;background-size: cover;background-position: center 0px;}
.home-hero-logo{max-width: 260px;width: 60%;padding: 200px 0;position: relative;z-index: 10;}
.home-hero-backdrop{position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 1;}

.home-welcome{max-width: 1100px;margin: 0 auto;padding: 60px 20px;}
.home-welcome .home-welcome-intro{font-size: 1.5rem;line-height: 1.4;margin: 0 auto 60px auto;max-width: 800px;}
.home-pillars{background-color: #EEF4F5;border-radius: 77px;display: flex;flex-direction: column;
	padding: 20px 20px;}
.home-pillar{padding: 30px 0;flex-grow: 1;width: 100%;border-bottom: 1px solid #B2C1C3;}
@media only screen and (min-width: 768px) {
	.home-welcome{padding: 60px 40px;}
	.home-pillar{padding: 30px 30px;}
}
@media only screen and (min-width: 980px) {
	.home-pillars{flex-direction: row;}
	.home-pillar{padding: 30px 30px;width: 33.33%;border-bottom: none;}
}
.home-pillar:last-child{border: none;}
.home-pillar h4{font-size: 1.5rem;font-weight: 600;margin: 30px 0 15px 0;}
.home-welcome .home-pillar p{font-size: 1.3rem;font-weight: 400;line-height: 1.4;}
.home-pillar-icon{width: 70px;display: block;margin: 0 auto;}

.home-section{margin: 0 auto;padding: 60px 20px;}
.home-section-hero{background-image: url('image/img-clearing.png');background-repeat: no-repeat;background-size: cover;background-position: center;
	margin: 0 auto 60px auto;max-width: 1020px;}
.home-section-hero-text{font-size: 3rem;padding: 150px 0;color: #FFF;text-align: center;text-shadow: 2px 2px rgba(34,49,67,0.31);
	font-weight: 600;}
.home-section-intro{font-size: 1.5rem;line-height: 1.4;margin: 0 auto 60px auto;max-width: 800px;}
@media only screen and (min-width: 768px) {
	.home-section{padding: 0px 40px 60px 40px;}
}

.home-section-image-gallery{display: flex;flex-direction: row;overflow-x: scroll;margin: 0 -40px;}

.home-stay{background-color: #223143;border-radius: 30px;padding: 40px;margin: 0 30px;}
.home-stay h3{color: #FFF;text-align: center;font-size: 2.8rem;font-weight: 500;margin: 0 0 10px 0;}
.home-stay-pre-sale{color: #86D60F;font-size: 1.8rem;text-align: center;margin: 0 0 10px 0;}
.home-stay h4{color: #90A1B7;text-align: center;font-size: 2rem;font-weight: 500;margin: 0 0 20px 0;}

.image-box{}
.image-box img{min-width: 200px;height: 500px;}

.input {font-size: 1.4rem;width: 100%;border: 1px solid #C0CCD8;box-sizing: border-box;border-radius: 12px;background:#F1F5F8;padding:10px 16px;line-height:1.5;}
.input:focus{background-color: #FCFDFF;border-color:#09CAEC;box-shadow: 0px 0px 3px #0062FF;outline:0;}
.input.w-sm{max-width: 60px;}
.input-container{position:relative;flex-grow: 1;}
.input-incrementer{max-width: 60px;text-align: center;font-weight: 700;}
.input-group{margin-bottom: 33px;}
.input-invisible{border: 1px solid transparent;background-color: transparent;}
.input-invisible:hover{border: 1px solid rgba(8,131,185,0.1);}
.input-label{font-size: 1.5rem;line-height: 1.4;margin-bottom: 12px;display: block;margin-bottom: 5px;font-weight: 500;}
.input-large{font-size: 1.85rem;font-weight: 500;}
.input-title{font-size: 1.85rem;font-weight: 700;}
.input-mb{margin-bottom: 20px;}
.input.input-icon-pl{padding-left:30px !important;}
.input-icon-box{position: absolute;top: 11px;left: 14px;}

input.input-invisible:-webkit-autofill,textarea.input-invisible:-webkit-autofill{-webkit-box-shadow:none;-webkit-text-fill-color: #003459;}
input.input-invisible:-webkit-autofill:focus,textarea.input-invisible:-webkit-autofill:focus{-webkit-box-shadow:none;-webkit-text-fill-color: #003459;background-color: #FFF;}
button.input-invisible:focus,input.input-invisible:focus,textarea.input-invisible:focus{border: 1px solid rgba(8,131,185,0.15);box-shadow:none;outline:none;background-color: rgba(8,131,185,0.02);}



.leaves-container{position: relative;min-height: 120px;}
.leaves-loader-container{position: relative;min-height: 120px;}
.leaves-media{display: flex;flex-direction: row;gap: 2%;flex-wrap: wrap;}
.leaves-media-actions{display: flex;flex-direction: row;justify-content: flex-end;margin-top: 11px;}
.leaves-media-item{background-color: #EAEFF2;min-width: 32%;max-width: 32%;min-height: 120px;
	display: flex;flex-direction: row;align-items: center;justify-content: center;margin-bottom: 10px;
	background-size: cover;background-position: center center;border-radius: 11px;position: relative;}
.leaves-media-item-interactive{cursor: pointer;transition:transform .3s ease-out, box-shadow .3s ease-out;box-shadow: 1px 1px 3px 3px transparent;}
.leaves-media-item-interactive:hover{transform: scale(1.1);z-index: 2;border: 2px solid #FFF;box-shadow: 3px 4px 12px 3px rgba(34,49,67,0.15);}
.leaves-media-item-remove{z-index: 2;display: flex;flex-direction: row;align-items: center;justify-content: center;
	position: absolute;top: -3px;right: -3px;border: 1px solid #223143;}
.leaves-media-item-remove-icon{width: 18px;height: 18px;}
.leaves-media-item-upload{z-index: 3;display: flex;flex-direction: row;align-items: center;justify-content: center;
	position: absolute;top: -3px;right: -3px;}
.leaves-media-input{visibility: hidden;width: 0;height: 0;position: absolute;}
.leaves-media-none{background: #EAEFF2;}
.leaves-media-item-upload-progress{position: absolute;top: 7px;left: 7px;right: 7px;height:3px;border-radius:3px;background-color: rgba(255,255,255,0.6)}
.leaves-media-item-upload-progress-bar{position: absolute;top: 0;left: 0;height: 3px;width: 50%;background-color: #0883B9;border-radius:3px;}

.leaves-conversation{display: flex;flex-direction: column;gap: 13px;justify-content: stretch;}
.leaves-conversation-item{min-height: 46px;flex-grow: 1;border-radius: 11px;background: #EAEFF2;position: relative;}
.leaves-conversation-item:after{content:'';position:absolute;right:21px;bottom:-12px;width:0px;height:6px;
  border-top:6px solid #EAEFF2;border-right:8px solid transparent;border-left:8px solid transparent;
	margin:0 -8px;}
.leaves-conversation-add{background: #EAEFF2;display:flex; 
	flex-direction: row;align-items: center;justify-content: stretch;}
.leaves-conversation-add-action{min-width: 90px;max-width: 90px;padding:0 17px;}
.leaves-conversation-add-input{flex-grow: 1;font-size: 1.25rem;border: none;}
.leaves-conversation-add-input.textarea.input-invisible:focus{border: 1px solid transparent;background: transparent;}
.leaves-conversation-add-input.input-invisible{}
.leaves-conversation-author{padding:11px 0 0 0;font-size:1.1rem;font-weight: 600;
	cursor: pointer;display: flex;flex-direction: row;align-items: flex-start;justify-content: flex-end;}
.leaves-conversation-author:hover{color: #0883B9;}
.leaves-conversation-body{padding: 17px;font-size: 1.25rem;}
.leaves-conversation-item-remove{position: absolute;
	top: -8px;right: -8px;}
.leaves-conversation-none{background: #EAEFF2;display:flex; 
	flex-direction: row;align-items: center;justify-content: center;}
.leaves-conversation-none-icon{width: 22px;height: 22px;color: #828FA0;}
.leaves-conversation-row{display: flex;flex-direction: column;align-items: stretch;}

.media-gallery{width: 100%;position: relative;min-height: 120px;}
.media-gallery-row{display: flex;flex-direction: row;width: 100%;}
.media-gallery-item{width: 50%;height: 30vw;background-size: cover;background-position: center center;border: 1px solid #FFF;border-radius: 11px;
	cursor: pointer;position: relative;transition:transform .3s ease-out, box-shadow .3s ease-out;box-shadow: 1px 1px 3px 3px transparent;
	float: left;}
.media-gallery-item:hover{transform: scale(1.1);z-index: 2;border: 2px solid #FFF;box-shadow: 3px 4px 12px 3px rgba(34,49,67,0.15);}
.media-gallery-item-btn{width: 50%;height: 30vw;float: left;display: flex;flex-direction: row;align-items: center;justify-content: center;
	background-color: #ECF1F5;border: 2px solid #FFF;}
.media-gallery-item-control{position: absolute;top: -14px;right: -16px;line-height: 1;opacity: 0;}
.media-gallery-item:hover .media-gallery-item-control{opacity: 1;}

.media-gallery-view{position: fixed;top: 0;bottom: 0;left: 0;right: 0;background-color: rgba(15, 75, 108, 0.85);z-index: 100;
	display: flex;flex-direction: row;align-items: center;justify-content: center;}
.media-gallery-view-image-frame{padding: 8px;background-color: #FFF;border-radius:11px;position: relative;
	box-shadow: 8px 12px 8px 4px rgba(34,49,67,0.2);}
.media-gallery-view-image{max-width: 90vw;max-height: 90vh;border-radius: 11px;}
.media-gallery-view-close{position: absolute;top: 3px;right: 3px;z-index: 101;
	box-shadow: 4px 6px 6px 2px rgba(34,49,67,0.2);}
.media-gallery-view-close:hover{box-shadow: 6px 8px 6px 4px rgba(34,49,67,0.2);}
.media-gallery-view-prev{margin: 0 -40px 0 0;z-index: 101;
	box-shadow: 4px 6px 6px 2px rgba(34,49,67,0.2);}
.media-gallery-view-prev:hover{box-shadow: 6px 8px 6px 4px rgba(34,49,67,0.2);}
.media-gallery-view-next{margin: 0 0 0 -40px;z-index: 101;
	box-shadow: 4px 6px 6px 2px rgba(34,49,67,0.2);}
.media-gallery-view-next:hover{box-shadow: 6px 8px 6px 4px rgba(34,49,67,0.2);}


@media only screen and (min-width: 768px) {
	.leaves-media-item{max-width: 30%;}

	.leaves-container{}
	.leaves-conversation-author{padding:0 17px;align-items: center;justify-content: center;min-width: 90px;max-width: 90px;}
	.leaves-conversation-row{flex-direction: row;align-items: flex-end;}
	.leaves-conversation-item:after{content:'';position:absolute;right: -22px;bottom: 25px;width: 6px;height: 0px;
		border-left:8px solid #EAEFF2;border-top:6px solid transparent;border-bottom:6px solid transparent;
		margin:-8px 0;}

	.media-gallery-item{width: 25%;height: 20vw;max-height: 200px;text-align: center;line-height: 20vw;}
	.media-gallery-item-btn{width: 25%;height: 20vw;max-height: 200px;float: left;text-align: center;line-height: 20vw;}

	.media-gallery-view-image-frame{padding: 16px;border-radius:33px;}
	.media-gallery-view-image{max-width: 80vw;max-height: 90vh;border-radius: 11px;}
	.media-gallery-view-close{top: 18px;right: 18px;}
	.media-gallery-view-prev{margin: 0 auto 0 18px;}
	.media-gallery-view-next{margin: 0 18px 0 auto;}
}


.member-action{color: #223143;font-weight: 500;cursor: pointer;font-size: 1.25rem;}
.member-action:hover{color: #03A9C2;}
.member-action:active{color: #03A9C2;}
.member-action-separator{color: #DDEBEC;margin: 0 10px;font-size: 1.75rem;}

.member-chapter{display: flex;flex-direction: row;align-items: center;margin-bottom:20px;cursor:pointer;
	transition: color 0.2s ease-out;}
.member-chapter:hover{color:#03A9C2;}
.member-chapter-left{;min-width:33%;margin-right:20px;}
.member-chapter-num{font-size:1.4rem;font-weight:700;text-align:right;}
.member-chapter-date{font-size:1.2rem;font-weight:400;text-align:right;}
.member-chapter-title{font-size:2.2rem;font-weight:900;}

.member-story-body{position: relative;padding-top: 70px;}
.member-details h2{text-align: left;margin-bottom: 20px;font-size: 2.25rem;}
.member-top{display: block;margin: 40px auto;padding: 40px;}
.member-edit-name{display: flex;flex-direction: row;margin-bottom: 20px;}
.member-edit-name .input{margin-right: 12px;}
.member-edit-name .input:last-child{margin-right: 0;}
.member-edit-name .input{margin-right: 12px;}
.member-edit{width: 100%;}
.member-edit-about{resize: none;margin-bottom: 20px;height: 200px;}
.member-edit-actions{display: flex;flex-direction: row;align-items: center;}
.member-edit-action-left{margin-right: auto;}

.member-main{}
.member-image-container{margin: 0 0 40px 0;}
.member-image-circle{background-color: #D8E2EF;border-radius: 99px;overflow: hidden;width: 160px;height: 160px;
	background-size: cover;background-position: center center;margin: 0 auto;}
.member-image{width: 160px;}
.member-image-actions{margin: 20px 0;text-align: center;}

.member-add-btn{float: right;}
.member-select-row{display: flex;flex-direction: row;align-items: center;}
.member-select-image{width: 50px;height: 50px;border-radius: 33px;background-position: center center;background-size: cover;}
.member-select-name{font-size: 1.5rem;padding-left: 12px;font-weight: 600;}
.members-select{margin-bottom: 30px;}
.member-select-dot{width: 24px;height: 24px;border-radius: 33px;border: 2px solid #7C8B9D;cursor: pointer;
	transition:color .3s ease-out,border-color .3s ease-out, transform .3s ease-out;margin-right: 16px;}
.member-select-dot:hover{border-color: #0883B9;cursor: pointer;
	transform: scale(1.1);box-shadow: 2px 3px 4px 2px rgba(34,49,67,0.05);}
.member-select-dot.selected{border-color: #0883B9;}
.member-select-dot.selected:after{content: '';width: 14px;height: 14px;margin: 3px;display: block;border-radius: 11px;background-color: #0883B9;}

.member-spot{cursor: pointer;transition:transform .3s ease-out, box-shadow .3s ease-out;box-shadow: 1px 1px 3px 3px transparent;
	position: relative;box-shadow: 1px 1px 3px 3px transparent;width: 44px;height: 44px;min-width: 44px;min-height: 44px;border-radius: 33px;
	background-size: cover;background-position: center center;flex-wrap: wrap;margin:0;display: block;
	display: flex;flex-direction: row;align-items: center;justify-content: center;background-color: #EAEFF2;}
.member-spot.no-hover{cursor: default;}
.member-spot:hover:not(.no-hover){transform: scale(1.1);z-index: 2;box-shadow: 1px 3px 4px 1px rgba(34,49,67,0.06);}
.member-spot:hover:not(.no-hover) .member-spot-tooltip{display: block;}
.member-spot-icon{width: 14px;height: 14px;color: #828FA0;}

@media only screen and (min-width: 768px) {
	.member-chapter-left{margin-right:60px;}

	.member-image-container{margin: 0 60px 0 0;min-width: 33%;}
	.member-image-circle{background-color: #D8E2EF;border-radius: 99px;overflow: hidden;width: 160px;height: 160px;
		background-size: cover;background-position: center center;}
	.member-image{width: 160px;}
	.member-top{display: flex;flex-direction: row;align-items: top;max-width: 900px;margin: 40px auto;padding: 40px;}
}


.members-hero{display: flex;flex-direction: row;align-items: center;justify-content: center;overflow: hidden;
	position: relative;background-color: #223143;
	background-image: url('image/img-team.png');background-repeat: no-repeat;background-size: cover;background-position: center 0px;}
.members-hero-logo{max-width: 120px;width: 30%;padding: 30px 0;position: relative;z-index: 10;}
.members-hero-backdrop{position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 1;}

.members-welcome{max-width: 1100px;margin: 0 auto;padding: 60px 20px;}
.members-welcome .members-welcome-intro{font-size: 1.25rem;line-height: 1.4;margin: 0 auto 60px auto;max-width: 800px;}

.members-list{display: flex;flex-direction: row;align-items: center;min-height: 70px;flex-wrap: wrap;max-width: 800px;
	margin:0 auto;justify-content: space-between;margin-bottom:40px;}
.members-member{cursor: pointer;transition:transform .3s ease-out, box-shadow .3s ease-out;box-shadow: 1px 1px 3px 3px transparent;border-radius: 33px;
	position: relative;float: left;box-shadow: 1px 1px 3px 3px transparent;width: 80px;height: 80px;border-radius: 99px;
	background-size: cover;background-position: center center;flex-wrap: wrap;margin: 0 10px 26px 0;}
.members-member-tooltip{position: absolute;top: 126px;left: -30px;right: -30px;color: #223143;font-size: 1.2rem;display: none;
	text-align: center;font-weight: 600;}
.members-member.no-hover{cursor: default;}
.members-member:hover{transform: scale(1.1);z-index: 2;border: 2px solid #FFF;box-shadow: 1px 3px 4px 1px rgba(34,49,67,0.06);}
.members-member:hover .members-member-tooltip{display: block;}
.members-members-add{margin-bottom: 17px;}

.members-join{padding: 30px 20px;max-width: 800px;margin: 0 auto;background-color: #EEF4F5;border-radius: 33px;}
.members-join h3{margin: 0 0 20px 0;text-align: center;font-weight: 600;font-size: 2rem;}
.members-join p{font-size: 1.4rem;line-height: 1.4;margin: 0 0 20px 0;}
.members-join-action{text-align: center;}

@media only screen and (min-width: 768px) {
	.members-list{margin-bottom:60px;}
	.members-welcome .members-welcome-intro{font-size: 1.5rem;line-height: 1.4;margin: 0 auto 60px auto;max-width: 800px;}
	.members-member{display: flex;flex-direction: row;align-items: center;margin: 0 20px 60px 0;width: 120px;height: 120px;}
	.members-member-img{width: 120px;min-width: 120px;height: 120px;margin: 0 30px 0 0;}
}

.method-row-top{display: flex;flex-direction: row;align-items: center;}
.method-row-title{font-size: 2.25rem;font-weight: 800;}
.method-row-overview{font-size:1.25rem;}

.modal {position:fixed;top:0;left:0;bottom:0;right:0;z-index:1000;display:none;overflow:hidden;outline:0;
	background-color: rgba(15, 75, 108, 0.85);}
.modal.fade{opacity:0;transition:opacity .3s ease-out;}
.modal.show{display:block;opacity:1;}
.modal.show .modal-dialog{transform: none;pointer-events:auto;}
.modal.fade .modal-dialog{transition:transform .3s ease-out;transform:translateY(-50px);pointer-events:none;}
.modal-body,.modal-content {}
.modal-body {position: relative;flex: 1 1 auto;padding:0 30px 50px 30px;}
.modal-body p{color: #223143;font-size: 1.35rem;margin: 0 0 24px 0;line-height: 1.4;}
.modal-body-action{text-align: right;}
.modal-body-action-btn-left{float: left;}
.modal-content {position:relative;display:flex;flex-direction:column;pointer-events:auto;
	background-color:#fff;background-clip:padding-box;border:1px solid rgba(0,0,0,.2);border-radius:99px;outline:0;
	margin: 0 20px;}
.modal-dialog {position:relative;width:auto;margin:50px auto;pointer-events: none;}
.modal-dialog-scrollable .modal-content {overflow:visible;}
.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {flex-shrink: 0;}
.modal-dialog-scrollable .modal-body {overflow-y:auto;}
.modal-form-element{margin-bottom: 30px;}
.modal-form-element textarea{width: 100%;resize: none;height: 150px;}
.modal-form-element p{margin-bottom: 12px;font-size: 1.5rem;font-weight: 600;}
.modal-header {display:flex;align-items:flex-start;justify-content:space-between;padding: 40px 30px 30px 30px;}
.modal-header .close{color:#6B7280;outline:none;background:transparent;border:none;padding:0.5rem;margin:0;
	position:absolute;top:37px;right: 42px;cursor:pointer;}
.modal-header .close:hover{color:#003459;}
.modal-header .close-icon{width: 24px;height: 24px;}
.modal-member{display: flex;flex-direction: row;align-items: center;justify-content: center;}
.modal-member-img{background-color: #D8E2EF;border-radius: 99px;overflow: hidden;width: 160px;height: 160px;
	background-size: cover;background-position: center center;}
.modal-member-label{font-size: 1.75rem;margin-left: 20px;font-weight: 700;}
.modal-preview-image{width: 200px;height: 200px;margin:0 auto;background-size: cover;background-position: center center;
	border-radius: 111px;}
.modal-previews{overflow: hidden;margin-bottom: 30px;}
.modal-preview-media{width: 50%;height: 200px;float: left;background-size: cover;background-position: center center;
	border-radius: 3px;border: 2px solid #FFF;}
.modal-title {font-size:2.3rem;color:#223143;padding:0;font-weight:800;margin:0;text-align: center;width: 100%;}

@media (min-width: 576px){
	.modal-dialog {max-width:600px;margin:100px auto;}
	.modal-dialog-scrollable {}
	.modal-dialog-scrollable .modal-content {max-height:calc(100vh - 200px);overflow-y:scroll;}
}
@media (min-width:992px){
	.modal-body {padding:0;position: relative;flex: 1 1 auto;padding:0 50px 50px 50px;}
	.modal-lg, .modal-xl {max-width:900px;}
}

.gap-r-1{margin-right: 8px;}

.p-3{padding: 10px 16px;}

.page{}
.page.mobile{font-size: 10px;}
.page-content{margin:0 0 0 0;position: relative;}

.page-hero{display: flex;flex-direction: row;align-items: center;justify-content: center;overflow: hidden;
	position: relative;background-color: #223143;
	background-image: url('image/img-community.png');background-repeat: no-repeat;background-size: cover;background-position: center 0px;}
.page-hero-logo{max-width: 120px;width: 30%;padding: 30px 0;position: relative;z-index: 10;}
.page-hero-backdrop{position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 1;}
@media only screen and (min-width: 768px) {
	.page-content{margin:0 0 0 110px;}
}

.page-loader{display: flex;flex-direction: row;align-items: center;min-height: 100vh;position: fixed;top: 0;bottom: 0;left: 0;right: 0;
	z-index: 1000;}
.page-loader-bg{position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-color: rgba(34,49,67,0.6);}
.page-loader-container{width: 100%;text-align: center;position: relative;}
.page-loader-container img{width: 60px;}

.page-nav{position: fixed;top: 0;bottom: 0;left: 0;width: 50px;height: 50px;z-index: 100;
	background-color: #223143;padding: 10px 0;overflow: hidden;border-radius: 0 0 7px 0;
	transition: height 0.4s ease-out, width 0.2s ease-out;}
.page-nav-logo{width: 30px;display: block;margin: 0 auto 15px auto;cursor: pointer;transition: color 0.2s ease-out;}
.page-nav.open{width: 110px;height: 230px;}
.page-nav-logo-btn{cursor: pointer;}
.page-nav-logo-btn .page-nav-logo{color: #FFF;}
.page-nav-logo-btn:hover .page-nav-logo{color: #03A9C2;}
.page-nav-logo-btn:active .page-nav-logo{color: #6EF6EC;}
.page-nav-links{padding: 0 10px;overflow: hidden;}
.page-nav-link{color: #FFF;text-decoration: none;font-size: 1.15rem;display: block;margin-bottom: 6px;transition: color 0.2s ease-out;
	cursor: pointer;font-weight: 500;}
.page-nav-link:hover{color: #03A9C2;}
.page-nav-link:active{color: #6EF6EC;}
.page-nav-link.active{color: #03A9C2;}
@media only screen and (min-width: 768px) {
	.page-nav{width: 110px;height: auto;padding: 20px 0;border-radius: 0;transition: none;}
	.page-nav-logo{width: 40px;color: #FFF;display: block;margin: 0 auto 20px auto;}
	.page-nav.open,.page-nav-links{height: auto;}
}

.page-section{max-width: 1100px;margin: 0 auto;padding: 60px 20px;}

.page-title{padding: 0.5rem 1rem;font-size: 1.5rem;color: #FFF;font-weight: 500;}
.page-subtitle{padding: 0.5rem 1rem;font-size: 1rem;}

.page-head{display: flex;flex-direction: row;align-items: center;background: #2A2A2A;
	padding: 0.5rem 0;}
.page-head .page-subtitle{padding-top: 0;color: #AFAFAF;}

.part-loader{display: flex;flex-direction: row;align-items: center;position: absolute;top: 0;bottom: 0;left: 0;right: 0;
	z-index: 1000;}
.part-loader-bg{position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-color: rgba(234,239,242,0.5);}
.part-loader-container{width: 100%;text-align: center;position: relative;}
.part-loader-container img{width: 60px;}

.pos-absolute{position: absolute !important;}
.pos-relative{position: relative !important;}

.story-body{position: relative;max-width: 1200px;margin: 0 auto;}
.story-intro{max-width: 1100px;margin: 0 auto;padding: 60px 20px 60px 40px;}
.story-promo{max-width: 1100px;margin: 0 auto;padding: 0 20px 60px 40px;}
.story-welcome{font-size: 2.5rem;font-weight: 400;line-height: 1.35;}

.story .highlight-block{margin-left: 20px;}

@media only screen and (min-width: 768px) {
	.story-intro{max-width: 1100px;margin: 0 auto;padding: 80px 40px 80px 80px;}
	.story-promo{max-width: 1100px;margin: 0 auto;padding: 0 40px 80px 80px;}
	.story-welcome{font-size: 1.5rem;font-weight: 400;line-height: 1.35;}
	.story .highlight-block{margin-left: auto;}
}
.textarea{resize:none;}

.timeline-chapter{margin: 20px 34px 0 34px;position: relative;}
.timeline-chapter-line{position: absolute;top: 10px;left: -8px;height: 3px;width: 29px;background-color: #BFD6F1;}
.timeline-dot{width: 14px;height: 14px;border: 5px solid #03A9C2;border-radius: 11px;position: absolute;top: 2px;left: -25px;background-color: #FFF;}
.timeline-dot-behind{width: 18px;height: 18px;border: 16px solid #FFF;border-radius: 11px;position: absolute;top: -5px;left: -35px;background-color: #FFF;}
.timeline-date{font-size: 1.25rem;font-weight: 600;margin: 0 0 0 4px;text-transform: uppercase;}
.timeline-line{position: absolute;left: 15px;top: 333px;bottom: 40px;width: 2px; background-color: #BFD6F1;border-radius: 3px;}
.timeline-action{position: absolute;right: 0;top: -4px;}
.timeline-chapter-add{position: relative;top: -2px;left: 1px;background-color: #FFF;}

.tooltip-container{position: relative;}
.tooltip-pos{position: absolute;left:50%;right: 50%;top: 0;z-index:50;}
.tooltip-pos-bottom-right{position: absolute;left:50%;right: 50%;bottom: 0;}
.tooltip{position: absolute;top: -40px;left: -16px;padding: 9px 12px;background-color: #223143;color: #FFF;
	font-size: 1rem;line-height: 1;font-weight: 500;border-radius: 3px;display: none;white-space:nowrap;
	text-transform: none;z-index: 50;}
.tooltip:after{content:'';position:absolute;left:17px;bottom:-6px;width:0px;height:6px;
  border-top:6px solid #223143;border-right:8px solid transparent;border-left:8px solid transparent;
	margin:0 -8px;}
.tooltip.tooltip-bottom-right{left: auto;right: -16px;top: auto;bottom: -40px;}
.tooltip.tooltip-bottom-right:after{left: inherit;right:17px;top:-6px;bottom: auto;width:0px;height:6px;
  border-bottom:6px solid #223143;border-right:8px solid transparent;border-left:8px solid transparent;
  	border-top: none;
	margin:0 -8px;}
.tooltip.tooltip-right{left: auto;right: -16px;}
.tooltip.tooltip-right:after{content:'';position:absolute;left: inherit;right:17px;bottom:-6px;width:0px;height:6px;
  border-top:6px solid #223143;border-right:8px solid transparent;border-left:8px solid transparent;
	margin:0 -8px;}
.tooltip-container:hover .tooltip{display: block;}

@media only screen and (min-width: 768px) {
	.timeline-chapter-add{position: relative;top: -2px;left: 16px;background-color: #FFF;}
	.timeline-dot{width: 18px;height: 18px;border: 6px solid #03A9C2;border-radius: 11px;position: absolute;top: 2px;left: -27px;background-color: #FFF;}
	.timeline-line{position: absolute;left: 30px;top: 666px;bottom: 40px;width: 3px; background-color: #BFD6F1;}
	.timeline-chapter{margin: 40px 50px 0 50px;position: relative;}
	.timeline-date{font-size: 1.5rem;font-weight: 600;margin: 0 0 0 40px;text-transform: uppercase;}
}

.tree-actions{margin-left: auto;display: flex;flex-direction: row;align-items: center;padding: 10px 0;
	justify-content: flex-end;}
.tree-action{width: 22px;height: 22px;min-width: 22px;background-color: #223143;color: #FFF;
	font-size: 1.2rem;font-weight: 800;text-align: center;transition:opacity .15s ease-in-out;
	border-radius: 11px;margin: 2px 0 2px 8px;min-width: 18px;cursor: pointer;opacity: 0.1;
	transition:opacity .15s ease-in-out;display: flex;flex-direction: row;align-items: center;justify-content: center;}
.tree-action-icon {width: 10px;height: 10px;}
.tree-bough:hover .tree-action, .tree-sprig:hover .tree-action{opacity: 0.2;}
.tree-actions .tree-action:hover{opacity: 1;}
.tree-actions .tree-action.active{opacity:0.9;background-color: #223143;}
.tree-actions .tree-action-media.active{}
.tree-actions .tree-action.active:after{content:'';position:absolute;left:11px;right:0;bottom:-12px;width:0px;height:6px;
  border-bottom:6px solid #223143;border-right:8px solid transparent;border-left:8px solid transparent;
	margin:0 -8px;}
.tree-actions .tree-action.published{opacity:0.9;background-color: #03A9C2;}

.tree-actions-drop{margin-left: auto;}

.tree-bottom{margin-top: 20px;text-align: right;}

.tree-branches{display: column;flex-direction: row;align-items: center;}

.tree-frame{margin: 0 auto;padding: 70px 10px;max-width: 1000px;}
.tree-info{display: flex;flex-direction: row;align-items: flex-start;margin: 0 10px 10px 10px;
	border-bottom: 1px dashed #EAEEF2;padding: 10px 0;}
.tree-info-label{padding: 7px 8px;font-size: 1.1rem;line-height: 1.5;}

.tree-inline{cursor: pointer;display: inline-block;}

.tree-inline-dot{display: inline-block;width: 20px;height: 20px;min-width: 22px;background-color: #223143;color: #FFF;
	font-size: 1.2rem;font-weight: 800;text-align: center;
	border-radius: 11px;margin: 0 4px 0 0;min-width: 18px;transition:background-color .15s ease-in-out;}
.tree-inline:hover .tree-inline-dot{background-color: #03A9C2;}
.tree-inline-dot-icon{display: inline-block;width: 10px;height: 10px;}
.tree-inline-label{font-weight: 700;font-size: 1.4rem;transition:color .15s ease-in-out;}
.tree-inline:hover .tree-inline-label{color: #03A9C2;}


.tree-intro{padding: 22px;font-size: 1.1rem;line-height: 1.5;background-color: #E1EFDA;}

.tree-family{background-color:#223143;display:inline-block;height:33px;line-height:33px;
	border-radius:17px 0 0 0;}
.tree-family-icon{width:14px;color:#FFF;margin:0 11px 0 17px;vertical-align: top;position:relative;top:9px;
	display:inline-block;}
.tree-family-label{color:#FFF;display:inline-block;margin:0 16px 0 0;
	font-size:1.25rem;font-weight:600;text-transform: uppercase;}

.tree-hero{width: 100%;}
.tree-hero img{width:100%;}

.tree-leaves{border-top:2px solid #223143;display: flex;align-items: center;flex-direction: row;}
.tree-leaves-title{padding:14px 8px 18px 8px;font-size: 1.15rem;font-weight: 700;text-transform: uppercase;}
.tree-leaves-main{flex-grow: 1;}

.tree-overactions{position: fixed;bottom: 0;left: 0;right: 0;z-index: 200;padding: 17px;
	background-color: rgba(34,49,67,0.3);display: flex;flex-direction: row;justify-content: flex-end;}

.tree-row:hover .tree-row-point{background-color: #0883B9;}
.tree-row:hover .tree-row-title{color: #0883B9;}
.tree-row:hover .tree-row-body{color: #0883B9;}


.tree-title{font-size: 2.25rem;font-weight: 800;padding: 10px 0;border-bottom: 1px dashed #EAEEF2;margin-bottom: 10px;}
.tree-title-editing{}
.tree-title-input{font-size:2.8rem;font-weight: 900;}
.tree-title-label{font-size:2.8rem;font-weight: 900;padding:17px 17px;}
.tree-title-border{border: 1px dashed #EAEEF2;}

.tree-top-item{background-color:#0883B9;display:inline-block;height:33px;line-height:33px;}
.tree-top-item-icon{width:14px;color:#FFF;margin:0 12px 0 16px;vertical-align: top;position:relative;top:9px;
	display:inline-block;}
.tree-top-item-label{color:#FFF;display:inline-block;margin:0 16px 0 0;
	font-size:1.25rem;font-weight:600;text-transform: uppercase;}

.tree-top{height:33px;border-radius:17px 17px 0 0;background-color: #EAEFF2;
	display: flex;flex-direction: row;align-items: center;}
.tree-top .tree-actions{margin-right: 5px;}

@media only screen and (min-width: 768px){
	.tree-actions{min-width: 90px;}
	.tree-frame{padding: 70px;}
	.tree-info{}
	.tree-info-label{padding: 15px 16px;font-size: 1.2rem;line-height: 1.5;}
	.tree-label-shape{font-size: 1.25rem;font-weight: 600;background-color: #EAEFF2;
	padding:  8px 30px 8px 30px;border-radius: 0 20px 20px 0;display:inline-block;}

	.tree-leaves{}
	
	.tree-leaves-title{padding:17px 17px 17px 17px;}
}

.visibility-hidden{visibility: hidden;}

.w-screen-125{width: 125vw;}



*/